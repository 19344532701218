import React from "react";
import Footer from "./footer";
import { Link } from "react-router-dom";
import Newsletter_reg from "./newsletter_registration"
import cusatimg from "../images/cusat2.png";

function newsletter() {
window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <div className="bg-black justify-items-center font-defmono text-white grid items-center w-full overflow-x-clip ">
     <div className="w-full h-auto justify-items-center ">
     <h1 className="text-center justify-items-center  text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
        NEWSLETTER
      </h1>
      <div className="w-full justify-items-center mobile:h-auto pc:h-screen p-8  text-center text-2xl">
      
        <p className="w-full">
          Explore the history and real-world impact of photonics and optics.
          Stay updated on the latest advancements, try hands-on experiments, and enjoy exclusive interviews with experts.
          Participate in fun activities like crosswords, puzzles, and quizzes to win prizes.
        </p>
        {/* <img
          src={cusatimg}
          alt=""
          className="w-[300px] pc:w-[80%] pc:h-auto pc:visible self-center m-auto   bg-white rounded-2xl pc:m-4 justify-self-center pc:mr-8 p-2 mb-2   "
        /> */}
        
          <Link
              to="/newsletter_registration"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className=" pl-2   hover:translate-y-2 transition duration-200 ease-in-out hover:text-red-600 hover:underline "
            >
            <div className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700">
            Register
            </div>
            </Link>
            
          {/* <Link
              to="/newsletter_jan"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className=" pl-2   hover:translate-y-2 transition duration-200 ease-in-out hover:text-red-600 hover:underline "
            >
              <div className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700">
                Newsletter Jan
              </div>
            </Link> */}
          
        {/* <a
                href="https://near.tl/sm/xKzwit1E7"
                className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
              >
                <p>Register now!</p>
              </a> */}
        
      </div>
     </div>
     <Footer/>
    </div>
    
  );
}

export default newsletter;
